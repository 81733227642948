<template>
  <main class="schedule pb-5">
    <Spinner v-if="getLoadings.listing" />
    <BContainer v-else>
      <Card class="schedule__card mx-auto">
        <SectionHeading :heading="$t('schedule.heading')" />
        <i18n
          path="schedule.text"
          class="schedule__instruction text-dark"
          tag="p"
        >
          <br />
          <br />
        </i18n>

        <p
          class="text-danger"
          v-if="getErrors.schedule && getErrors.schedule.length"
        >
          {{ getErrors.schedule }}
        </p>
        <div class="bg-light-gray p-3 mb-3">
          <p class="text-primary fw-bold mb-2">
            {{ $t("schedule.brokerPreferenceText") }}
          </p>
          <div class="row">
            <div class="col-7">
              <div class="row">
                <div
                  class="col-6"
                  v-for="viewingDate in getListing.listing
                    .preferredViewingDates"
                  :key="viewingDate"
                >
                  {{ viewingDate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Form
          :formFields="formFields"
          @on-submit="onSubmit"
          @liveValueUpdate="onLiveValueUpdate"
          :emitLiveValuesOf="emitLiveValuesOf"
          :addWatcherOnce="false"
        >
          <template #formfooter>
            <div class="mb-sm-6 mb-5 d-flex">
              <ButtonWithIcon
                @click.native="addSuggestions"
                class="ml-auto d-block text-black"
                :text="$t('schedule.addDate')"
              >
                <Plus />
              </ButtonWithIcon>
              <ButtonWithIcon
                @click.native="removeSuggestions"
                class="ml-2 d-block text-black"
                :text="$t('schedule.removeDate')"
              >
                <Times />
              </ButtonWithIcon>
            </div>
            <BButton
              :disabled="getLoadings.schedule"
              variant="primary"
              type="submit"
              class="mx-auto d-block schedule__send"
              >{{ $t("schedule.submitBtn") }}

              <b-spinner
                v-if="getLoadings.schedule"
                small
                label="Spinning"
                variant="white"
              ></b-spinner>
            </BButton>
          </template>
        </Form>
      </Card>
    </BContainer>
  </main>
</template>

<script>
import SectionHeading from "@/components/general/SectionHeading.vue";
import Card from "@/components/general/Card.vue";
import ButtonWithIcon from "../components/ui/ButtonWithIcon.vue";
import Plus from "@/components/svg/Plus.vue";
import Form from "@/components/ui/form/Form.vue";
import { mapGetters, mapActions } from "vuex";
import Times from "../components/svg/Times.vue";
import Spinner from "../components/ui/Spinner.vue";
export default {
  components: {
    SectionHeading,
    Card,
    ButtonWithIcon,
    Plus,
    Form,
    Times,
    Spinner,
  },
  data() {
    return {
      currentSuggestions: 1,
      formFields: [],
      emitLiveValuesOf: ["date1"],
      disabledDates: {},
    };
  },
  computed: {
    ...mapGetters("listing", ["getListing"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    futureDateTimestamp() {
      let creationDate = "";
      if (this.$route.query.creationDate)
        creationDate = new Date(this.$route.query.creationDate);
      else creationDate = new Date();
      creationDate.setMonth(creationDate.getMonth() + 1);
      return creationDate.getTime();
    },

    // formFields() {
    //   return [
    //     {
    //       name: "date1",
    //       component: "Datepicker",
    //       value: "",
    //       rules: "required",
    //       placeholder: this.$t("date"),
    //       colClasses: "col-12 col-sm-6 pr-sm-1 pr-3",
    //       disablePastDates: true,
    //       disableWeekends: true,
    //       disableFutureDates: true,
    //       futureDateTimestamp:this.futureDateTimestamp
    //     },
    //     {
    //       name: "time1",
    //       component: "SelectInput",
    //       value: "No Preference",
    //       rules: "required",
    //       placeholder: "Time",
    //       colClasses: "col-12 col-sm-6 pl-sm-1 pl-3",
    //       options: [
    //         {
    //           text: this.$t("schedule.noPreference"),
    //           value: "No Preference",
    //         },
    //         {
    //           text: "09:00 : 12:00",
    //           value: "09:00 : 12:00",
    //         },
    //         {
    //           text: "13:00 : 17:00",
    //           value: "13:00 : 17:00",
    //         },
    //       ],
    //     },
    //   ];
    // },
  },
  methods: {
    ...mapActions("listing", ["setSchedule", "setListing"]),
    addSuggestions() {
      if (this.currentSuggestions >= 10) return;
      this.currentSuggestions += 1;
      this.formFields.push({
        name: `date${this.currentSuggestions}`,
        component: "Datepicker",
        value: "",
        rules: "required",
        placeholder: this.$t("date"),
        colClasses: "col-12 col-sm-6 pr-sm-1 pr-3",
        disablePastDates: true,
        disableFutureDates: true,
        disableWeekends: true,
        futureDateTimestamp: this.futureDateTimestamp,
        disabledDates: Object.values(this.disabledDates),
      });
      this.formFields.push({
        name: `time${this.currentSuggestions}`,
        component: "SelectInput",
        value: "No Preference",
        rules: "required",
        placeholder: "Time",
        colClasses: "col-12 col-sm-6 pl-sm-1 pl-3",
        options: [
          {
            text: this.$t("schedule.noPreference"),
            value: "No Preference",
          },
          {
            text: "09:00 : 12:00",
            value: "09:00 : 12:00",
          },
          {
            text: "13:00 : 17:00",
            value: "13:00 : 17:00",
          },
        ],
      });
      this.emitLiveValuesOf.push(`date${this.currentSuggestions}`);
    },
    removeSuggestions() {
      if (this.currentSuggestions < 2) return;
      this.emitLiveValuesOf.pop();
      this.currentSuggestions -= 1;
      this.formFields.pop();
      this.formFields.pop();
    },
    async onSubmit(data) {
      const listingId = this.$route.params.listingId;
      const schedules = [];
      for (let key in data) {
        const formattedKey = key.replace(/[0-9]/g, "");
        const currentIdx = key.charAt(key.length - 1) - 1;
        if (!schedules[currentIdx])
          schedules[currentIdx] = { [formattedKey]: data[key] };
        schedules[currentIdx][formattedKey] = data[key];
      }
      await this.setSchedule({ schedules, listingId });
      this.$router.push(`/listings/requested?listingId=${listingId}`);
    },
    onLiveValueUpdate(data) {
      for (let key in data) {
        const currentDateArr = data[key].split("-");
        const date = `${currentDateArr[1]}-${currentDateArr[0]}-${currentDateArr[2]}`;
        this.disabledDates[key] = date;
      }
      this.formFields.forEach((field, i) => {
        if (field.name.indexOf("date") > -1) {
          const newField = { ...field };
          newField.disabledDates = Object.values(this.disabledDates);
          this.formFields.splice(i, 1, newField);
        }
      });
    },
  },
  created() {
    this.formFields.push({
      name: "date1",
      component: "Datepicker",
      value: "",
      rules: "required",
      placeholder: this.$t("date"),
      colClasses: "col-12 col-sm-6 pr-sm-1 pr-3",
      disablePastDates: true,
      disableWeekends: true,
      disableFutureDates: true,
      futureDateTimestamp: this.futureDateTimestamp,
      disabledDates: Object.values(this.disabledDates),
    });
    this.formFields.push({
      name: "time1",
      component: "SelectInput",
      value: "No Preference",
      rules: "required",
      placeholder: "Time",
      colClasses: "col-12 col-sm-6 pl-sm-1 pl-3",
      options: [
        {
          text: this.$t("schedule.noPreference"),
          value: "No Preference",
        },
        {
          text: "09:00 : 12:00",
          value: "09:00 : 12:00",
        },
        {
          text: "13:00 : 17:00",
          value: "13:00 : 17:00",
        },
      ],
    });
    const listingId = this.$route.params.listingId;
    this.setListing({ query: "type=schedule", listingId });
  },
  watch: {
    "$i18n.locale": function () {
      this.formFields.forEach((field, i) => {
        const newField = { ...field };
        if (field.name.indexOf("time") > -1) {
          newField.options.splice(0, 1, {
            text: this.$t("schedule.noPreference"),
            value: "No Preference",
          });
        }

        this.formFields.splice(i, 1, newField);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  margin-top: 4.375rem;
  &__instruction {
    font-size: 1.125rem;
    @include xs {
      font-size: 1rem;
    }
  }
  &__send {
    width: 75%;
    @include xs {
      width: 100%;
    }
  }
}
</style>
