<template>
  <BButton class="btn btn-white" v-bind="$attrs">
    <slot></slot>
    <span class="ml-2">{{ text }}</span>
    <slot name="after"></slot>
  </BButton>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-with-icon {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: $border-radius;
  border-color: $info;
  padding: 0.75rem 1rem;
  font-size: 1rem;

  &.light-gray {
    background: $light-gray;
  }
  &.tomato {
    background: $danger;
  }
  &.success {
    background: $success;
  }
  &.text-sm {
    font-size: 0.875rem;
  }
  &.text {
    &-black {
      color: #000000;
    }
    &-dark {
      color: $dark;
    }
  }
  @include xs {
    padding: 0.75rem 0.5rem;
  }
}
</style>
